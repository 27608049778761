<template>
  <LoginComponent v-if="!isAuthenticated" @login-success="handleLoginSuccess" />
  <div v-else class="flex flex-col justify-center">
    <NavBar @update-barber="handleBarberUpdate" :last-update="lastUpdate" />
    <div class="flex-1 p-2 flex flex-col md:flex-row md:gap-x-2 gap-y-2 md:gap-y-0 min-h-0">
      <div class="flex flex-col gap-y-2">
        <StatisticsComp :appointments-count="appointmentsCount" />
        <CalendarComp
          @update-date="handleDateUpdate"
          @update-calendar="handleCalendarUpdate"
          @update-calendar-month="handleCalendarByMonthUpdate"
          @toggle-exception-date="closeExceptionDate"
          :appointmentsData="appointmentsData"
          :exceptionalTimes="exceptionalTimes" />
        <AppointmentComp
          ref="appointmentCompRef"
          :selected-date="selectedDate"
          :appointment-info="filteredAppointments"
          :selected-barber="selectedBarber"
          :button-state="buttonState"
          :button-request-message="buttonRequestMessage"
          :exceptionalTimes="exceptionalTimes"
          @submit-appointment="sendAppointmentData"
          @update-button-state="handleButtonStateUpdate"
          @update-button-request-message="handleButtonErrorMessage" />
      </div>
      <TableComp
        :selectedDate="selectedDate"
        :appointmentInfo="filteredAppointments"
        @update-appointment="updateAppointmentHandler" />
    </div>
    <ExceptionDateTimePicker
      :isExceptionPickerVisible="isExceptionPickerVisible"
      :exceptionalTimes="exceptionalTimes"
      @add-exception="addExceptionTimes"
      @delete-exception="deleteException"
      @close-modal="closeExceptionDate" />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import TableComp from "./components/TableComp.vue";
import CalendarComp from "./components/calendarComp.vue";
import AppointmentComp from "@/components/AppointmentComp.vue";
import StatisticsComp from "@/components/StatisticsComp.vue";
import ExceptionDateTimePicker from "@/components/ExpectionDateTimePicker.vue";
import LoginComponent from "@/components/LoginComponent.vue";

import axios from "axios";

import { useAuthStore } from "@/store/index";
import { mapState } from "pinia";

export default {
  name: "App",
  components: {
    NavBar,
    TableComp,
    CalendarComp,
    AppointmentComp,
    StatisticsComp,
    ExceptionDateTimePicker,
    LoginComponent,
  },
  data() {
    return {
      selectedBarber: "Beto Almeida",
      appointmentDetails: [],
      selectedDate: new Date(),
      appointmentsCount: {
        appointments: {
          daily: null,
          weekly: null,
          monthly: null,
        },
        presence: {
          monthly: {
            present: null,
            notPresent: null,
          },
        },
      },
      buttonState: "idle",
      buttonRequestMessage: "",
      lastUpdate: "",
      intervalId: null,
      appointmentsData: {
        counts: [],
        dates: [],
      },
      exceptionalTimes: [],
      isExceptionPickerVisible: false,
    };
  },
  created() {
    const authStore = useAuthStore();
    authStore.checkAuth();
    if (authStore.isAuthenticated) {
      this.initializeApp();
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Clear the interval when the component is destroyed
  },
  computed: {
    ...mapState(useAuthStore, ["isAuthenticated", "username"]),
    filteredAppointments() {
      return this.appointmentDetails.filter((appointment) => appointment.barber === this.selectedBarber);
    },
  },
  methods: {
    handleLoginSuccess({ token, username }) {
      const authStore = useAuthStore();
      authStore.login(token, username);
      this.initializeApp();
    },
    initializeApp() {
      this.fetchAppointments();
      this.fetchTotalAppointmentsData();
      this.handleCalendarUpdate();
      this.lastUpdate = new Date().toLocaleString();
      this.getExceptionTimes();

      this.intervalId = setInterval(() => {
        this.fetchAppointments();
        this.fetchTotalAppointmentsData();
        this.handleCalendarUpdate();
        this.lastUpdate = new Date().toLocaleString();
      }, 300000); // 5 Minutes = 300000ms
    },

    async addExceptionTimes(newException) {
      // this.exceptionalTimes.push(newException);
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/exceptions`, newException);
        this.getExceptionTimes();
      } catch (error) {
        console.error("Error creating exception:", error);
      }
    },
    async getExceptionTimes() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/exceptions`);
        const exceptions = response.data;
        this.exceptionalTimes = exceptions;
      } catch (error) {
        console.error("Error fetching exceptions:", error);
      }
    },
    async deleteException(exceptionId) {
      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/exceptions/${exceptionId}`);
        this.getExceptionTimes();
      } catch (error) {
        console.error("Error deleting exception:", error);
      }
    },

    closeExceptionDate() {
      this.isExceptionPickerVisible = !this.isExceptionPickerVisible;
    },
    handleDateUpdate(newDate) {
      this.selectedDate = newDate ? newDate : new Date();
      this.$refs.appointmentCompRef.resetForm();
      this.fetchAppointments();
      this.fetchTotalAppointmentsData();
      this.buttonState = "idle";
    },
    async handleCalendarUpdate() {
      try {
        const date = new Date();
        const isoDateTime = new Date(this.selectedDate.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/appointments/month/${isoDateTime}`);
        this.appointmentsData = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async handleCalendarByMonthUpdate(data) {
      try {
        const [year, month] = data[0].id.split("-");
        const date = new Date();
        const isoDateTime = new Date(Date.UTC(year, month - 1, 1) - date.getTimezoneOffset() * 60000).toISOString();
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/appointments/month/${isoDateTime}`);
        this.appointmentsData = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    handleBarberUpdate(barber) {
      this.selectedBarber = barber;
    },
    handleButtonStateUpdate(newButtonState) {
      this.buttonState = newButtonState;
    },
    handleButtonErrorMessage(errorMessage) {
      this.buttonRequestMessage = errorMessage;
    },
    async sendAppointmentData(appointment) {
      try {
        this.buttonState = "loading";
        await axios.post(`${process.env.VUE_APP_API_URL}/api/appointments/`, appointment);
        await this.fetchAppointments();
        await this.fetchTotalAppointmentsData();
        await this.handleCalendarUpdate();
        this.buttonState = "success";
      } catch (error) {
        this.buttonState = "error";
        if (error.response.data.error) {
          this.buttonRequestMessage = this.parseErrorMessage(error.response.data.error);
        } else {
          this.buttonRequestMessage = "ERRO!";
        }
      }
    },
    async fetchAppointments() {
      try {
        const date = new Date();
        const isoDateTime = new Date(this.selectedDate.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/appointments/${isoDateTime}`);
        this.appointmentDetails = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async fetchTotalAppointmentsData() {
      try {
        const date = new Date();
        const isoDateTime = new Date(this.selectedDate.getTime() - date.getTimezoneOffset() * 60000).toISOString();
        const responseData = await axios.get(`${process.env.VUE_APP_API_URL}/api/appointments/totals/${isoDateTime}`);
        this.appointmentsCount = responseData.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    parseErrorMessage(errorMessage) {
      if (errorMessage.includes("time: Path `time` is required")) {
        return "Escolha a hora!";
      }
      if (errorMessage.includes("customerName: Path `customerName` is required")) {
        return "Nome de Cliente Obrigatório!";
      }
      return errorMessage;
    },
    updateAppointmentHandler(type, data) {
      if (type === "remove") this.removeAppointment(data);
      if (type === "updatePresence") this.updateClientPresence(data);
    },
    async removeAppointment(appointmentToRemove) {
      this.appointmentDetails = this.appointmentDetails.filter((appointment) => {
        return appointment.date !== appointmentToRemove.date || appointment.time !== appointmentToRemove.time;
      });
      await this.fetchTotalAppointmentsData();
      await this.handleCalendarUpdate();
    },
    async updateClientPresence(data) {
      const appointmentToUpdate = this.appointmentDetails.find((appointment) => appointment._id === data.id);
      appointmentToUpdate.clientPresent = data.presence;
      await this.fetchTotalAppointmentsData();
    },
  },
};
</script>

<style lang="postcss">
* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #0f0f0f;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
  @apply flex flex-col;
}

img {
  @apply pointer-events-none select-none;
}

button[disabled] {
  opacity: 20%;
  background-color: gray;
  cursor: not-allowed;
}
</style>
