import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import "./style.css";
// import Keycloak from 'keycloak-js'

// let initOptions = {
//     url: 'https://keycloak.paradoxserver.com/auth', //the url of the Keycloak Server
//     realm: 'Focus-realm', //replace with your realm
//     clientId: 'myclient', //replace with your client id
//     onLoad: 'login-required'
// }

// let keycloak = new Keycloak(initOptions);

// keycloak.init({
//     onLoad: initOptions.onLoad,
//     checkLoginIframe: false
// }).then((auth) => {
//     if (!auth) {
//         window.location.reload();
//     } else {
//         console.log("Authenticated");
//     }

//     // Make the keycloak instance available throughout your application
//     let app = createApp(App);
//     app.config.globalProperties.$keycloak = keycloak;
//     app.mount('#app');
// }).catch(() => {
//     console.log("Authenticated Failed");
// });

let app = createApp(App);
const pinia = createPinia();

app.use(pinia).mount("#app");
