const hasValue = (input) => {
  switch (typeof input) {
    case "boolean":
      return true;
    case "number":
      return true;
    default:
      return !!input;
  }
};

const isAValidName = (name) => {
  const trimmedName = name.trim();

  // Check for empty input
  if (trimmedName.length === 0) {
    return false;
  }

  // Minimum length requirement (e.g. 2 characters)
  const minimumLength = 4;
  if (trimmedName.length < minimumLength) {
    return false;
  }

  // Regular expression modification to enforce at least one character
  const regex =
    /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿÝÀÂÄÃÅÇÉÈÊËÝÌÎÝÑÓÒÔÖÕÚÙÛÜÝŸ\s']+$/gu;

  if (trimmedName.match(regex)) {
    return true;
  }

  return false;
};

const isAValidEmailAddress = (email) => {
  // Trim the input email
  const trimmedEmail = email.trim();

  // Regular expression for email validation
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regex.test(trimmedEmail);
};

const isAValidPhoneNumber = (contact) => {
  if (
    hasOnlyNumbers(contact) &&
    hasMin4Chars(contact) &&
    hasMax12Chars(contact)
  ) {
    return true;
  }
  return false;
};

const hasOnlyNumbers = (input) => {
  let regex = /^[0-9]*$/g;
  if (input.match(regex)) {
    return true;
  }
  return false;
};

const hasMin2Chars = (input) => {
  if (input.length >= 2) {
    return true;
  }
  return false;
};

const hasMin4Chars = (input) => {
  if (input.length >= 4) {
    return true;
  }
  return false;
};

const hasMax12Chars = (input) => {
  if (input.length <= 12) {
    return true;
  }
  return false;
};

export {
  hasValue,
  isAValidName,
  isAValidEmailAddress,
  isAValidPhoneNumber,
  hasMin2Chars,
};
