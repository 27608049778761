<template>
    <div v-show="isExceptionPickerVisible"
        class="z-20 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80">
        <div class="rounded-lg border-[#4dd89f] border-2 h-fit w-[80%] md:w-[400px] p-4 bg-[#0F2B24]">
            <label class="block text-white text-sm font-bold mb-2" for="selectedDate">
                Seleciona a Data:
            </label>
            <input type="date" id="selectedDate" v-model="selectedDate"
                class="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500" />

            <label class="block text-white text-sm font-bold mt-4 mb-2">
                A partir de que horário?
            </label>

            <select v-model="selectedTime" class="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500">
                <option v-for="timeInterval in timeIntervals" :key="timeInterval" :value="timeInterval">
                    {{ timeInterval }}
                </option>
            </select>

            <label class="block text-white text-sm font-bold mt-4 mb-2" for="exceptionStatus">
                Estado:
            </label>

            <select v-model="exceptionStatus"
                class="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500">
                <option value="open">Aberto</option>
                <option value="closed">Fechado</option>
            </select>

            <button @click="addToExceptionArray"
                class="mt-4 bg-[#4dd89f] hover:bg-[#38a176] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                Adicionar Dia
            </button>
            <div class="mt-6 text-white p-2 border-2 border-[#4dd89f] rounded">
                <p class="opacity-50" v-if="exceptionalTimes.length <= 0">
                    Lista Vazia
                </p>
                <ul class="flex flex-col gap-y-2">
                    <li v-for="exception in exceptionalTimes" :key="exception._id"
                        class="flex items-center justify-between ">
                        <span>{{ formatDate(exception.date) }} - {{ exception.startTime }}</span>
                        <div class="flex gap-x-5 items-center">
                            <span
                                :class="{ 'dot-green': exception.status === 'open', 'dot-red': exception.status === 'closed' }"></span>
                            <button @click="deleteException(exception._id)"
                                class="bg-red-600 active:bg-red-600 hover:bg-red-700 px-2 py-2 text-sm font-medium border-2 rounded-md">
                                Delete
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <button @click="closeModal"
                class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue">
                Fechar
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedDate: null,
            selectedTime: null,
            timeIntervals: [
                "Manhã",
                "Tarde",
                "Dia Completo",
                "9:30",
                "10:15",
                "11:00",
                "11:45",
                "12:30",
                "14:30",
                "15:15",
                "16:00",
                "16:45",
                "17:30",
                "18:15",
                "19:00",
            ],
            exceptionStatus: "open",
        };
    },
    props: {
        exceptionalTimes: Array,
        isExceptionPickerVisible: Boolean,
    },
    emits: ["add-exception", "delete-exception", "close-modal"],
    methods: {
        addToExceptionArray() {
            if (this.selectedDate && this.selectedTime) {
                const newException = {
                    date: this.selectedDate,
                    startTime: this.selectedTime,
                    status: this.exceptionStatus,
                };

                this.$emit("add-exception", newException);

                this.selectedDate = null;
                this.selectedTime = null;
                this.isVisible = false;
            }
        },
        deleteException(exceptionId) {
            this.$emit("delete-exception", exceptionId);
        },
        formatDate(dateString) {
            const options = { day: "2-digit", month: "2-digit", year: "numeric" };
            const formattedDate = new Date(dateString).toLocaleDateString(
                "pt-PT",
                options
            );
            return formattedDate;
        },
        closeModal() {
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped lang="postcss">
input {
    cursor: pointer;
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.dot-green {
    width: 10px;
    height: 10px;
    background-color: green;
    /* Adjust the color as needed */
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    /* Adjust the margin as needed */
}

.dot-red {
    width: 10px;
    height: 10px;
    background-color: red;
    /* Adjust the color as needed */
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
    /* Adjust the margin as needed */
}</style>
