<template lang="">
    <div class="flex bg-[#141414] gap-1 rounded-lg border-2 border-[#4dd89f] p-1 flex-wrap">
        <div class="bg-[#0F2B24] text-[#96F2D7] p-2 rounded flex-1">
            <p class="font-semibold text-sm md:text-base">Marcações (Mês)</p>
            <p class="font-bold text-xl">{{ appointmentsCount.appointments.monthly }}</p>
        </div>
        <div class="bg-[#0F2B24] text-[#96F2D7] p-2 rounded flex-1">
            <p class="font-semibold text-sm md:text-base">Marcações (Semana)</p>
            <p class="font-bold text-xl">{{ appointmentsCount.appointments.weekly }}</p>
        </div>
        <div class="bg-[#0F2B24] text-[#96F2D7] p-2 rounded flex-1">
            <p class="font-semibold text-sm md:text-base w-min mx-auto">Marcações (Dia)</p>
            <p class="font-bold text-xl">{{ appointmentsCount.appointments.daily }}</p>
        </div>
        <div class="bg-[#0F2B24] text-[#96F2D7] p-2 rounded flex-1 w-min">
            <p class="font-semibold text-sm md:text-base">Cortes Mensais</p>
            <p class="font-bold text-xl">{{ appointmentsCount.presence.monthly.present }}</p>
        </div>
        <div class="bg-[#0F2B24] text-[#96F2D7] p-2 rounded flex-1">
            <p class="font-semibold text-sm md:text-base">Faltas Mensais</p>
            <p class="font-bold text-xl">{{ appointmentsCount.presence.monthly.notPresent }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        appointmentsCount: Object,
    }
}
</script>

<style lang="">
    
</style>