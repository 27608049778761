<template>
  <div
    ref="container"
    class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#190822] bg-opacity-50 opacity-0">
    <div
      class="bg-gradient-to-b from-[#000] to-[#190822] py-8 px-8 rounded-lg shadow-lg shadow-[#220b2e]/50 w-[26rem] font-roboto text-white border border-[#ffffff21]">
      <h2 class="text-3xl">Focus - Men's Salon</h2>
      <h3 class="text-xl mb-6">Admin</h3>
      <div>
        <div class="mb-4">
          <label class="block text-xs mb-2 text-left" for="username">Username</label>
          <input
            v-model="username"
            type="text"
            id="username"
            name="username"
            @blur="checkIfIsAValidName"
            @focus="(inputErrorMessage.name = []), (this.buttonText = 'Iniciar sessão')"
            placeholder="Introduza o Username"
            class="w-full px-3 py-2 border border-[#ffffff21] rounded-lg bg-[#12041a]"
            :class="{ 'has-errors-input': inputErrorMessage.name.length > 0 }" />
        </div>
        <div class="mb-4">
          <label class="block text-xs mb-2 text-left" for="password">Palavra-passe</label>
          <input
            v-model="password"
            type="password"
            id="password"
            name="password"
            placeholder="Introduza a palavra-passe"
            @focus="(inputErrorMessage.name = []), (this.buttonText = 'Iniciar sessão')"
            class="w-full px-3 py-2 border border-[#ffffff21] rounded-lg bg-[#12041a]" />
          <button></button>
        </div>
        <button
          class="rounded-lg border-2 border-purple-600 bg-purple-400/10 px-4 py-2 w-full flex justify-center relative"
          :class="{ 'button-valid': enableButtonLogin, 'button-invalid': !enableButtonLogin }"
          :disabled="!enableButtonLogin"
          @click="login">
          <p ref="text">{{ buttonText }}</p>
          <img
            ref="imageloading"
            class="loading-spinner opacity-0"
            v-show="showLoading"
            src="@/assets/loading.svg"
            alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";

import { isAValidName, hasMin2Chars } from "@/utils/validateInputs.js";
import { invalidName } from "@/utils/errorMessages.js";

export default {
  name: "loginComponent",
  data() {
    return {
      username: "",
      password: "",
      inputErrorMessage: {
        name: [],
      },
      buttonText: "Iniciar sessão",
      showLoading: false,
      isButtonDisabled: false,
    };
  },

  mounted() {
    gsap.to(this.$refs.container, { duration: 0.5, opacity: 1 });
  },

  computed: {
    enableButtonLogin() {
      return this.inputErrorMessage.name.length == 0 && this.username != "" && this.password != "";
    },
  },
  methods: {
    async login() {
      this.showLoading = true;
      this.isButtonDisabled = true;
      gsap.to(this.$refs.imageloading, { opacity: 1, duration: 0.2 });
      gsap.to(this.$refs.imageloading, { rotation: 360, duration: 2, ease: "none", repeat: -1 });
      gsap.to(this.$refs.text, { opacity: 0, duration: 0.2 });

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
          username: this.username,
          password: this.password,
        });
        gsap.to(this.$refs.imageloading, {
          opacity: 0,
          duration: 0.2,
          onComplete: () => {
            this.showLoading = false;
            gsap.killTweensOf(this.$refs.imageloading);
          },
        });
        gsap.to(this.$refs.text, { opacity: 1, duration: 0.2 });
        this.buttonText = "Login com sucesso!";

        const { token, username } = response.data;

        this.$emit("login-success", { token, username });

        setTimeout(() => {
          this.close();
        }, 500);
      } catch (error) {
        gsap.to(this.$refs.imageloading, {
          opacity: 0,
          duration: 0.1,
          onComplete: () => {
            this.showLoading = false;
            gsap.killTweensOf(this.$refs.imageloading);
          },
        });
        gsap.to(this.$refs.text, { opacity: 1, duration: 0.2 });
        if (error.response.data.message) {
          this.buttonText = error.response.data.message;
        } else {
          this.buttonText = error.message;
        }
      }
    },
    checkIfIsAValidName() {
      if (this.username && this.username !== "") {
        this.inputErrorMessage.name = [];
        if (isAValidName(this.username)) {
          if (hasMin2Chars(this.username)) {
            return true;
          } else {
            this.inputErrorMessage.name.push(invalidName);
            return false;
          }
        }
        this.inputErrorMessage.name.push(invalidName);
        return false;
      } else return;
    },
    close() {
      gsap.to(this.$refs.container, {
        duration: 0.5,
        opacity: 0,
        onComplete: () => {},
      });
    },
  },
};
</script>

<style scoped lang="postcss">
input:focus {
  outline: none !important;
  box-shadow: none;
  border-color: white;
}

.remove-underline:hover {
  text-decoration: none;
}

.has-errors-input {
  border: 1px solid rgb(255, 0, 0) !important;
}

.step {
  margin-bottom: 5px;
}

.step::before {
  content: "○";
  margin-right: 5px;
}

.step-done::before {
  content: "●";
  color: green;
  margin-right: 5px;
}

.button-valid {
  @apply cursor-pointer opacity-100 hover:bg-purple-400/20;
}

.button-invalid {
  @apply cursor-not-allowed opacity-20;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

.text,
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner {
  width: 24px;
  height: 24px;
}

button[disabled] {
  opacity: 20%;
  background-color: transparent !important;
  cursor: not-allowed;
}
</style>
