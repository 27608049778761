import { defineStore } from "pinia";
import { isTokenExpired } from "../utils/auth";
import axios from "axios";

export const useAuthStore = defineStore({
  id: "auth",
  // Define your state
  state: () => ({
    isAuthenticated: !!localStorage.getItem("token"), // Check if token exists
    userName: "",
  }),

  // Define your actions
  actions: {
    login(token, username) {
      localStorage.setItem("token", token);
      this.isAuthenticated = true;
      this.username = username;
      this.token = token;
    },
    logout() {
      localStorage.removeItem("token");
      this.isAuthenticated = false;
      this.username = "";
      this.token = "";
    },
    async checkAuth() {
      const token = localStorage.getItem("token");
      if (!token || isTokenExpired(token)) {
        this.logout();
        return;
      }
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/verify-token`, { token });
        if (response.data.valid) {
          this.token = token;
          this.username = response.data.username;
          this.isAuthenticated = true;
        } else {
          this.logout();
        }
      } catch (error) {
        console.error("Token verification failed", error);
        this.logout();
      }
    },
  },
});
