<template>
  <div
    class="flex gap-4 bg-[#141414] rounded-lg border-[#4dd89f] border-2 p-5 w-full flex-col md:flex-row"
  >
    <div class="w-full flex justify-center">
      <div class="grid grid-cols-3 gap-4 w-full">
        <button
          v-for="time in timeSlots"
          :key="time"
          class="rounded-md p-2 flex justify-center items-center text-lg font-semibold"
          :class="{
            'enabled-time-slot': !isTimeDisabled(time),
            'disabled-time-slot': isTimeDisabled(time),
            'selected-time-slot': selectedTimeSlot === time,
          }"
          @click="selectTimeSlot(time)"
        >
          {{ time }}
        </button>
      </div>
    </div>

    <div class="w-full flex flex-col">
      <div class="flex gap-2 flex-col">
        <input
          v-model="name"
          required
          type="text"
          class="form-input rounded-md"
          placeholder="Nome"
        />
        <input
          v-model="email"
          type="text"
          class="form-input rounded-md"
          placeholder="Email"
        />
        <input
          v-model="phone"
          type="tel"
          class="form-input rounded-md"
          placeholder="Número de telemóvel"
          pattern="\d*"
        />
        <textarea
          v-model="notes"
          placeholder="Notas"
          class="resize-none p-[10px]"
        ></textarea>
        <ButtonConfirm
          @click="submitAppointment"
          :button-state="buttonState"
          :button-request-message="buttonRequestMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonConfirm from "@/components/ButtonConfirmComp.vue";

export default {
  name: "AppointmentsPicker",
  components: {
    ButtonConfirm,
  },
  props: {
    appointmentInfo: Array,
    selectedBarber: String,
    buttonState: String,
    buttonRequestMessage: String,
    selectedDate: Date,
    exceptionalTimes: Array,
  },
  emits: [
    "submit-appointment",
    "update-button-state",
    "update-button-request-message",
  ],
  data() {
    return {
      selectedTimeSlot: null,
      timeSlots: [
        "9:30",
        "10:15",
        "11:00",
        "11:45",
        "12:30",
        "14:30",
        "15:15",
        "16:00",
        "16:45",
        "17:30",
        "18:15",
        "19:00",
      ],
      name: "",
      email: "",
      phone: "",
      notes: "",
      disabledTime: [],
    };
  },
  created() {
    this.refreshDisabledTime();
  },
  methods: {
    selectTimeSlot(time) {
      this.selectedTimeSlot = time;
      this.$emit("update-button-state", "idle");
    },
    isTimeDisabled(time) {
      return (
        this.exceptionalTimesByDate(this.selectedDate, time) ||
        this.disabledTime.includes(time) ||
        this.isTimeSlotInPast(time)
      );
    },
    exceptionalTimesByDate(date, time) {
      if (date != null) {
        // Check if the date matches any exceptional case
        const matchingExceptionalTime = this.exceptionalTimes.find(
          (exceptionalTime) => this.isSameDate(date, exceptionalTime.date)
        );

        if (matchingExceptionalTime) {
          // If the exceptional start time is a specific time slot
          if (this.timeSlots.includes(matchingExceptionalTime.startTime)) {
            const startTimeIndex = this.timeSlots.indexOf(
              matchingExceptionalTime.startTime
            );
            const currentTimeIndex = this.timeSlots.indexOf(time);
            if (currentTimeIndex >= startTimeIndex) {
              return true;
            }
          } else {
            // If the exceptional start time is "morning", "afternoon", or "full day"
            // Map exceptional start time to time range
            let timeRange;
            switch (matchingExceptionalTime.startTime) {
              case "Manhã":
                timeRange = ["9:30", "12:30"];
                break;
              case "Tarde":
                timeRange = ["14:30", "19:00"];
                break;
              case "Dia Completo":
                timeRange = ["9:30", "19:00"];
                break;
              default:
                return false; // Invalid exceptional start time
            }

            // Check if the given time falls within the time range
            const startTimeIndex = this.timeSlots.indexOf(timeRange[0]);
            const endTimeIndex = this.timeSlots.indexOf(timeRange[1]);
            const currentTimeIndex = this.timeSlots.indexOf(time);
            if (
              currentTimeIndex >= startTimeIndex &&
              currentTimeIndex <= endTimeIndex && matchingExceptionalTime.status == "closed"
            ) {
              return true;
            }
          }
        }
      }

      return false;
    },
    isSameDate(date1, date2) {
      const formattedDate = new Date(date2);
      return (
        date1.getFullYear() === formattedDate.getFullYear() &&
        date1.getMonth() === formattedDate.getMonth() &&
        date1.getDate() === formattedDate.getDate()
      );
    },
    isTimeSlotInPast(timeSlot) {
      const currentDate = new Date();
      const timeSlotDate = new Date(this.selectedDate);
      const timeSlotParts = timeSlot.split(":");
      timeSlotDate.setHours(
        parseInt(timeSlotParts[0]),
        parseInt(timeSlotParts[1])
      );

      return timeSlotDate < currentDate;
    },
    refreshDisabledTime() {
      this.disabledTime = this.appointmentInfo
        .filter((item) => this.timeSlots.includes(item.time))
        .map((appointment) => appointment.time);
    },
    submitAppointment() {
      const errorMessage = this.getValidationErrorMessage();

      if (errorMessage) {
        this.$emit("update-button-state", "error");
        this.$emit("update-button-request-message", errorMessage);
        return;
      }

      const TimeSlot = this.selectedTimeSlot;
      const TimeSlotParts = TimeSlot.split(":");
      const TimeSlotHours = parseInt(TimeSlotParts[0]);
      const TimeSlotMinutes = parseInt(TimeSlotParts[1]);

      const dateFormat = this.selectedDate;
      dateFormat.setHours(TimeSlotHours, TimeSlotMinutes, 0);
      const appointmentdate = new Date(
        dateFormat.getTime() - dateFormat.getTimezoneOffset() * 60000
      ).toISOString();

      this.$emit("submit-appointment", {
        date: appointmentdate,
        time: this.selectedTimeSlot,
        barber: this.selectedBarber,
        customerName: this.name,
        customerEmail: this.email,
        customerPhone: this.phone,
        notes: this.notes,
      });
      this.resetForm();
    },
    resetForm() {
      this.selectedTimeSlot = null;
      this.name = "";
      this.email = "";
      this.phone = "";
      this.notes = "";
    },
    getValidationErrorMessage() {
      if (!this.selectedTimeSlot) {
        return "Por favor, selecione um horário disponível.";
      }
      if (!this.name) {
        return "Por favor, insira um nome.";
      }
      this.$emit("update-button-state", "idle");
      return "";
    },
  },
  watch: {
    appointmentInfo: {
      handler() {
        this.refreshDisabledTime();
      },
      deep: true,
    },
    name() {
      this.$emit(
        "update-button-request-message",
        this.getValidationErrorMessage()
      );
    },
  },
};
</script>

<style scoped lang="postcss">
.selected-time-slot {
  border-color: #96f2d7 !important;
  background-color: #0f2b24 !important;
  color: #96f2d7 !important;
}

textarea {
  -webkit-border-radius: 0.365rem;
  -moz-border-radius: 0.365rem;
  border-radius: 0.365rem;
}

.disabled-time-slot {
  border: 1px solid gray;
  background-color: #2b2b2b;
  opacity: 20%;
  cursor: not-allowed !important;
  pointer-events: none;
  color: #fff;
}

.enabled-time-slot {
  @apply bg-[#e7d3cc] border border-[#ffffff] hover:bg-[#fae4dd] text-black cursor-pointer;
}

.form-input {
  padding: 5px 10px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #f1f1f1;
  outline: none;
  transition: border-color 0.3s;
}

.form-input:hover,
.form-input:focus {
  border-color: #74e4b2;
}

.vc-title span::first-letter {
  text-transform: uppercase;
}
</style>
